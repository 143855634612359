import request from '@/utils/request'

export function getTypeOptions () {
  return request({
    url: '/api/replaceStorage/GetTypeOptions',
    method: 'get'
  })
}

export function getStatusOptions () {
  return request({
    url: '/api/replaceStorage/GetStateOptions',
    method: 'get'
  })
}

export function getList (data) {
  return request({
    url: '/api/replaceStorage/List',
    method: 'post',
    data
  })
}

export function create (data) {
  return request({
    url: '/api/replaceStorage/CreateOrder',
    method: 'post',
    data
  })
}

export function edit (data) {
  // console.log(data)
  return request({
    url: '/api/replaceStorage/EditOrder',
    method: 'post',
    data
  })
}

export function editRemark (data) {
  return request({
    url: '/api/replaceStorage/EditOrderRemark',
    method: 'post',
    data
  })
}

export function addReturnNumber (data) {
  console.log(data)
  return request({
    url: '/api/replaceStorage/AddReturnTrackNumber',
    method: 'post',
    data
  })
}

export function addReturnNumber2 (data) {
  console.log(data)
  return request({
    url: '/api/replaceStorage/AddReturnTrackNumber2',
    method: 'post',
    data
  })
}

export function editTrackingNumber (data) {
  // console.log(data)
  return request({
    url: '/api/replaceStorage/EditOrderTrackNumber',
    method: 'post',
    data
  })
}

export function editTrackingNumber2 (data) {
  // console.log(data)
  return request({
    url: '/api/replaceStorage/EditOrderTrackNumber2',
    method: 'post',
    data
  })
}

export function editTrackingNumber3 (data) {
  // console.log(data)
  return request({
    url: '/api/replaceStorage/EditOrderTrackNumber3',
    method: 'post',
    data
  })
}

export function deleteTrackingNumberById (id) {
  // console.log(data)
  return request({
    url: '/api/replaceStorage/DeleteOrderTrackNumber',
    method: 'get',
    params: { id }
  })
}

export function deleteTrackingNumberById2 (id) {
  return request({
    url: '/api/replaceStorage/DeleteOrderTrackNumber2',
    method: 'get',
    params: { id }
  })
}

export function getProductListByUser (id) {
  return request({
    url: '/api/replaceStorage/GetProductListByUser',
    method: 'get',
    params: { userId: id }
  })
}

export function deleteReturnTrackNumber (id) {
  return request({
    url: '/api/replaceStorage/DeleteReturnTrackNumber',
    method: 'get',
    params: { id }
  })
}

export function deleteById (id) {
  return request({
    url: '/api/replaceStorage/DeleteById',
    method: 'get',
    params: { id }
  })
}

export function cancelReplace (id) {
  return request({
    url: '/api/replaceStorage/CancelReplace',
    method: 'get',
    params: { id }
  })
}

export function getById (id) {
  return request({
    url: '/api/replaceStorage/GetInfo',
    method: 'get',
    params: { id }
  })

}

export function outReplaceGoods (id, details, trackingNumber, time, number, company) {
  console.log(id)
  console.log(details)
  console.log(trackingNumber)
  return request({
    url: '/api/replaceStorage/ReplaceGoods',
    method: 'post',
    data: {
      id,
      details,
      trackingNumber,
      time,
      number,
      company
    },
  })
}

// export function sendAdminMessage () {
//   return request({
//     url: '/api/replaceStorage/SendAdminMessage',
//     method: 'get'
//   })
// }

export function selectOrderByOwerUser (owerUser) {
  return request({
    url: '/api/replaceStorage/SelectOrderByOwerUser',
    method: 'get',
    params: { owerUser }
  })
}

