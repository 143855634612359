<template>
  <div>
    <el-card>
      <el-form size="mini" :inline="true" :model="form">
        <el-form-item label="订单信息">
          <el-input
            clearable
            v-model="form.keyWorlds"
            placeholder="请输入订单相关信息"
          />
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select
            clearable
            v-model="form.status"
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="item in statusOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select clearable v-model="form.type" placeholder="请选择订单类型">
            <el-option
              v-for="item in typeOptions"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="寄回状态">
          <el-select
            clearable
            v-model="form.isReturn"
            placeholder="请选择寄回状态"
          >
            <el-option label="未寄回" :value="false"></el-option>
            <el-option label="寄回" :value="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getReplaceList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="success" icon="el-icon-plus" @click="goAddPage"
            >新增订单</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-table
        v-loading="loading"
        :data="list"
        height="calc(100vh - 60px - 80px - 180px)"
      >
        <el-table-column type="index" label="#" width="40"></el-table-column>
        <el-table-column label="订单号" prop="number">
          <template slot-scope="{ row }">
            <span style="font-size: 12px">{{ row.number }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="">
          <template slot-scope="{ row }">
            <el-tag size="mini">{{ row.typeDipaly }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="">
          <template slot-scope="{ row }">
            <el-tag :type="getTagType(row)" size="mini">{{
              row.statusDipaly
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="关联订单号" prop="associatedOrderNumber">
          <template slot-scope="{ row }">
            <span style="font-size: 12px">{{ row.associatedOrderNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户名" prop="owinUserName">
          <template slot-scope="{ row }">
            <span style="font-size: 12px">{{ row.owinUserName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="个人/公司" prop="companyName">
          <template slot-scope="{ row }">
            <span style="font-size: 12px">{{ row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="创建时间"
          prop="createTime"
        >
          <template slot-scope="{ row }">
            <span style="font-size: 12px">{{ row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否寄回" prop="returnStatus">
          <template slot-scope="{ row }">
            <el-tag
              size="mini"
              :type="getReturnTagType(row)"
              @click="openNewRetrunReplaceDialog(row.id)"
              >{{ row.returnStatusDisplay }}</el-tag
            >

            <!-- <el-tag
              size="mini"
              :type="getReturnTagType(row)"
              @click="openIsReturn(row)"
              >{{ row.returnStatusDisplay }}</el-tag
            > -->
            <!-- <el-tag v-if="row.returnStatus==2" size="mini" type="success" @click="openIsReturn(row)">寄回</el-tag>
            <el-tag v-else-if="row.returnStatus==1" size="mini" type="warning" @click="openIsReturn(row)">部分寄回</el-tag>
            <el-tag v-else size="mini" type="danger" @click="openIsReturn(row)">未寄回</el-tag> -->
          </template>
        </el-table-column>
        <el-table-column label="邮寄方式" prop="payType">
          <template slot-scope="{ row }">
            <p>{{ row.payType == false ? '寄付' : '到付' }}</p>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark">
          <template slot-scope="{ row }">
            <span
              class="el-icon-edit"
              style="cursor: pointer"
              @click="openEditOrderRemarkDialog(row)"
              >{{ row.remark }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              title="详情"
              size="mini"
              style="color: #85ce61"
              type="text"
              icon="el-icon-more"
              @click="openDetailDialog(row)"
            ></el-button>
            <el-button
              title="编辑"
              v-if="row.status == 3"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="goEditPage(row)"
            ></el-button>
            <el-button
              title="删除"
              v-if="row.status == 3"
              size="mini"
              style="color: red"
              type="text"
              icon="el-icon-delete"
              @click="deleteData(row)"
            ></el-button>
            <el-button
              title="取消"
              v-if="row.status != 3 && row.status != 4"
              size="mini"
              style="color: red"
              type="text"
              icon="el-icon-delete"
              @click="CancelData(row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        :current-page="form.pageIndex"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        :page-sizes="[10, 20, 50, 100]"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </el-card>

    <!-- 详情对话框 -->
    <el-dialog
      title="订单详情"
      :destroy-on-close="true"
      width="1000px"
      :visible.sync="showInfoDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="infoData">
        <el-form-item label="订单编号">
          {{ infoData.number }}
        </el-form-item>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="订单类型">
              <el-tag size="mini">{{ infoData.typeDipaly }}</el-tag>
            </el-form-item></el-col
          >
          <el-col :span="12">
            <el-form-item label="订单状态">
              <el-tag size="mini" :type="getTagType(infoData)">{{
                infoData.statusDipaly
              }}</el-tag>
            </el-form-item></el-col
          >
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="所属客户">
              {{ infoData.owinUserName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属个人/公司">
              {{ infoData.companyName }}
            </el-form-item></el-col
          >
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="收件人">
              {{ infoData.addressee }}
            </el-form-item></el-col
          >
          <el-col :span="12">
            <el-form-item label="联系方式">{{
              infoData.phone
            }}</el-form-item></el-col
          >
        </el-row>

        <el-form-item label="收货地址">
          {{ infoData.address }}
          <el-link type="primary" @click="copyInfo(infoData)">复制</el-link>
        </el-form-item>
      </el-form>

      <el-table :data="infoData.details">
        <el-table-column
          align="center"
          type="index"
          label="#"
          width="40"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productName"
          label="名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="modelName"
          label="型号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="productSpecs"
          label="规格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productPrice"
          label="价格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="productAmount"
          label="数量"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sendAmount"
          label="已发数"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="waitSendAmount"
          label="待发数"
        ></el-table-column>
        <el-table-column align="center" label="寄回数">
          <template slot-scope="{ row }">
            {{ infoData.isReturn ? row.productAmount : row.returnAmout }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="待寄回数">
          <template slot-scope="{ row }">
            <span style="color: red; font-weight: bold">
              {{ infoData.isReturn ? 0 : row.waitReturnAmout }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
      </el-table>
      操作历史:
      <div v-if="returnInfos.length > 0">
        <div v-for="item in returnInfos" :key="item.id">
          {{ item.updateTime }} 寄回快递 寄回单号:{{
            item.returnTrackingNumber
          }}
          <span>备注:{{ item.remark != '' ? item.remark : '无' }}</span>
        </div>
      </div>
      <div v-for="(item, index) in outStorageData" :key="'inGood' + index">
        <el-link
          v-if="outStorageData && infoData.status != 4"
          type="info"
          @click="openReplaceGoodsDetialDialog(item.outproductList)"
          >{{ item.createTime }} 替换货发货 快递单号:{{
            item.TrackingNumber
          }}</el-link
        >
        <el-link
          v-if="infoData.status != 4"
          type="primary"
          @click="copyToClipboard(item.TrackingNumber)"
          >复制</el-link
        >
        <el-link
          v-if="infoData.status != 4"
          type="info"
          @click="openNewTrackingDialog(item)"
          style="margin-left: 5px; margin-right: 5px"
          >修改</el-link
        >
        <el-link
          v-if="infoData.status != 4"
          type="danger"
          @click="deleteTrackingNumber(item.id)"
          >删除</el-link
        >
        <el-link
          v-if="outStorageData && infoData.status == 4"
          type="info"
          disabled
          >{{ item.createTime }} 替换货发货 快递单号:{{
            item.TrackingNumber
          }}</el-link
        >

        <el-link v-if="infoData.status == 4" type="danger" disabled
          >已取消</el-link
        >

        <el-dialog
          title="修改替换货快递单号"
          :visible.sync="changeDialogVisible"
          append-to-body
          :close-on-click-modal="false"
        >
          <el-form inline>
            <el-form-item label="原快递单号:">
              <el-input
                type="text"
                :disabled="true"
                v-model="newFormTrackingNumber.TrackingNumber"
                placeholder="请输入快递单号"
                style="width: 220px"
              ></el-input>
            </el-form-item>
            <el-form-item label="新快递单号:">
              <el-input
                type="text"
                v-model="newFormTrackingNumber.newTrackingNumber"
                placeholder="请输入新的快递单号"
                style="width: 220px"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="info" @click="changeDialogVisible = false"
              >取消</el-button
            >
            <el-button type="primary" @click="changeTrackingNumber"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <div>{{ replaceCreateTime }} 创建替换单</div>

      <div slot="footer">
        <el-button
          :disabled="infoData.isReturn"
          type="primary"
          @click="detailAppendReturnDataHandle"
          >添加返还信息</el-button
        >

        <el-button type="success" v-if="infoData.status == 6" disabled
          >已出库</el-button
        >
        <el-button type="success" v-else-if="infoData.status == 4" disabled
          >已取消出库</el-button
        >
        <el-button type="success" v-else @click="openOutDetailDialog"
          >去出库</el-button
        >
        <el-button @click="showInfoDialogVisible = false">取消</el-button>
      </div>
      <!-- 去出库 -->
      <el-dialog
        title="出库单填写"
        :visible.sync="goOutDialogVisible"
        append-to-body
        :close-on-click-modal="false"
      >
        <!-- 填写出库单 -->
        <el-form inline>
          <el-form-item label="快递单号:">
            <el-input
              type="text"
              v-model="deliveryGoodsProductForm.customerNumber"
              placeholder="请输入快递单号"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="false" label="出库时间:">
            <div class="block">
              <el-date-picker
                v-model="deliveryGoodsProductForm.goOutTime"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </el-form>
        <el-row>
          <el-table
            :data="deliveryGoodsProductData"
            stripe
            style="width: 100%"
            border
            :key="tablekey"
          >
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="型号">
              <template slot-scope="scope">
                {{ scope.row.modelName || scope.row.productModel || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="商品总数" prop="productAmount">
            </el-table-column>
            <el-table-column label="已发货数量" prop="sendAmount">
            </el-table-column>
            <el-table-column label="发货数量" :min-width="120">
              <template slot-scope="scope">
                <el-input-number
                  :min="0"
                  :max="scope.row.productAmount - scope.row.sendAmount"
                  size="small"
                  v-model="scope.row.count"
                  @change="() => (tablekey = !tablekey)"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="goOutDialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="deliveryGoodsDialogCheck"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 出库单详情 -->
      <!-- 订单详情 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="showOutDetialDialogVisible"
      >
        <el-row>
          <el-table
            :data="ReplaceOutproductList"
            stripe
            style="width: 100%"
            border
          >
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="发货数量" prop="productAmount">
            </el-table-column>
            <el-table-column prop="modelName" label="型号"> </el-table-column>
            <el-table-column prop="productSpecs" label="规格">
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-button size="mini" @click="printDataDialog = true"
            >预览打印出库单</el-button
          >
          <el-button size="mini" @click="zjPrintPage">直接打印</el-button>
        </el-row>

        <el-dialog append-to-body :visible.sync="printDataDialog">
          <!-- startprint -->
          <div ref="printBox">
            <template v-for="i in Math.ceil(ReplaceOutproductList.length / 10)">
              <div :key="'li' + i" class="print_box">
                <div class="print_box_head">
                  <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                  <barcode
                    class="barcode_style"
                    :width="1"
                    :font-size="12"
                    :height="25"
                    :value="infoData.number"
                  />
                </div>
                <div class="print_box_info">
                  <div>
                    出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                  </div>
                  <div>业务类型&nbsp;{{ infoData.typeDipaly }}</div>
                  <div>仓库&nbsp;杭州</div>
                  <div>出库类别&nbsp;替换货发货</div>
                  <div>销售部门&nbsp;国内销售</div>
                  <div>客户&nbsp;{{ infoData.owinUserName }}</div>
                  <div>
                    关联订单号&nbsp;{{ infoData.associatedOrderNumber }}
                  </div>
                  <div style="width: 100%">
                    备注&nbsp;{{ infoData.companyName }}&nbsp;{{
                      infoData.remark
                    }}
                  </div>
                </div>
                <div class="print_table_box">
                  <table
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    style=""
                    width="700"
                  >
                    <thead>
                      <tr>
                        <th>替换货名称</th>
                        <th>规格型号</th>
                        <th>数量</th>
                        <th>备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="item in pagination(i, 10, ReplaceOutproductList)"
                      >
                        <tr v-if="!item.isDelete" :key="item.id">
                          <td width="260">
                            {{ item.productName }}
                          </td>
                          <td width="100">
                            {{
                              (item.modelName || '') +
                              '' +
                              (item.productSpecs || '')
                            }}
                          </td>
                          <td width="80">{{ item.productAmount }}</td>
                          <td width="90">{{ '' }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="print_box_foot">
                  <div>制单人&nbsp;智美OA</div>
                  <div>审核人</div>
                  <div style="flex: 1; text-align: right">
                    总计:{{ deliveryGoodsDetialCount }}件
                  </div>
                  <div style="flex-grow: 1; text-align: right">
                    第{{ i }}页&nbsp;共{{
                      Math.ceil(ReplaceOutproductList.length / 10)
                    }}页
                  </div>
                </div>
              </div>
              <div
                v-if="i != Math.ceil(ReplaceOutproductList.length / 10)"
                :key="'pageNex' + i"
                style="page-break-after: always"
              ></div>
            </template>
          </div>
          <!-- endprint -->
          <div slot="footer" class="dialog-footer">
            <el-button @click="printDataDialog = false">取 消</el-button>
            <el-button type="primary" @click="printpage">打 印</el-button>
          </div>
        </el-dialog>
        <!-- 直接打印部分 start -->
        <div style="display: none" ref="zhijieprintBox">
          <template v-for="i in Math.ceil(ReplaceOutproductList.length / 10)">
            <div :key="'li' + i" class="print_box">
              <div class="print_box_head">
                <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                <barcode
                  class="barcode_style"
                  :width="1"
                  :font-size="12"
                  :height="25"
                  :value="infoData.number"
                />
              </div>
              <div class="print_box_info">
                <div>
                  出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                </div>
                <div>业务类型&nbsp;{{ infoData.typeDipaly }}</div>
                <div>仓库&nbsp;杭州</div>
                <div>出库类别&nbsp;替换货发货</div>
                <div>销售部门&nbsp;国内销售</div>
                <div>客户&nbsp;{{ infoData.owinUserName }}</div>
                <div>关联订单号&nbsp;{{ infoData.associatedOrderNumber }}</div>
                <div style="width: 100%">
                  备注&nbsp;{{ infoData.companyName }}&nbsp;{{
                    infoData.remark
                  }}
                </div>
              </div>
              <div class="print_table_box">
                <table
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                  style=""
                  width="700"
                >
                  <thead>
                    <tr>
                      <th>替换货名称</th>
                      <th>规格型号</th>
                      <th>数量</th>
                      <th>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="item in pagination(i, 10, ReplaceOutproductList)"
                    >
                      <tr v-if="!item.isDelete" :key="item.id">
                        <td width="260">
                          {{ item.productName }}
                        </td>
                        <td width="100">
                          {{
                            (item.modelName || '') +
                            '' +
                            (item.productSpecs || '')
                          }}
                        </td>
                        <td width="80">{{ item.productAmount }}</td>
                        <td width="90">{{ '' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="print_box_foot">
                <div>制单人&nbsp;智美OA</div>
                <div>审核人</div>
                <div style="flex: 1; text-align: right">
                  总计:{{ deliveryGoodsDetialCount }}件
                </div>
                <div style="flex-grow: 1; text-align: right">
                  第{{ i }}页&nbsp;共{{
                    Math.ceil(ReplaceOutproductList.length / 10)
                  }}页
                </div>
              </div>
            </div>
            <div
              v-if="i != Math.ceil(ReplaceOutproductList.length / 10)"
              :key="'pageNex' + i"
              style="page-break-after: always"
            ></div>
          </template>
        </div>
        <!-- 直接打印部分 end -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="showOutDetialDialogVisible = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 寄回状态修改 -->
    <el-dialog
      :close-on-click-modal="false"
      append-to-body
      :visible.sync="showIsReturnDialogVisible"
      title="寄回状态修改"
    >
      <el-form inline>
        <el-form-item
          label="历史单号:"
          v-if="infoData.returnTrackingNumber != ''"
        >
          <el-input
            type="textarea"
            autosize
            v-model="infoData.returnTrackingNumber"
            style="width: 480px"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递单号:">
          <el-input
            type="text"
            v-model="newReturnNumber"
            placeholder="请输入快递单号"
            style="width: 480px"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单备注:">
          <el-input
            type="text"
            v-model="newReturnRemark"
            style="width: 480px"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否全部寄回:">
          <!-- <el-input
            type="text"
            v-model="infoData.isReturn"
            placeholder="请输入快递单号" style="width:280px;"
          ></el-input> -->
          <template>
            <el-radio v-model="infoData.isReturn" :label="true">是</el-radio>
            <el-radio v-model="infoData.isReturn" :label="false">否</el-radio>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showIsReturnDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="isReturnChange()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 新寄回单 -->
    <el-dialog
      title="邮寄详情"
      append-to-body
      :visible.sync="newReturnDialogVisible"
    >
      <div>
        <el-button
          :disabled="newReturnForm.isReturn"
          type="success"
          @click="appendReturnDataHandle"
          >添加返还信息</el-button
        >
      </div>

      <div>
        <div
          style="
            margin: 10px 0;
            border: 1px solid #ccc;
            padding: 10px;
            box-sizing: border-box;
          "
          v-for="item in newReturnForm.returnList"
        >
          <div class="return_item">
            <span>
              快递编号: {{ item.returnTrackingNumber
              }}<span style="margin: 0 10px"></span>
            </span>
            <span> 创建时间:{{ item.createTime }} </span>
            <span>
              <el-button
                type="text"
                style="color: red"
                @click="deleteReturnTrackNumberHandler(item)"
                >删除</el-button
              >
            </span>
          </div>
          <el-row>
            <el-table
              :data="item.productList"
              stripe
              style="width: 100%"
              border
              :key="tablekey"
            >
              <el-table-column type="index" label="#"></el-table-column>
              <el-table-column label="商品名称" prop="productName">
              </el-table-column>
              <el-table-column label="型号">
                <template slot-scope="scope">
                  {{ scope.row.modelName || scope.row.productModel || '无' }}
                </template>
              </el-table-column>
              <el-table-column label="规格">
                <template slot-scope="scope">
                  {{ scope.row.productSpecs || '无' }}
                </template>
              </el-table-column>
              <el-table-column label="返还数量" prop="count"> </el-table-column>
            </el-table>
          </el-row>
        </div>
      </div>

      <div slot="footer">
        <el-button
          size="mini"
          type="primary"
          @click="newReturnDialogVisible = false"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 返还信息对话框 -->
    <el-dialog :visible.sync="returnDataDialogVisible">
      <el-form :inline="true">
        <el-form-item label="返还快递单号">
          <el-input
            v-model="returnDataForm.trackingNumber"
            clearable
            placeholder="请填写返还的快递单号"
          />
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input
            v-model="returnDataForm.remark"
            clearable
            placeholder="返还备注"
          />
        </el-form-item>
      </el-form>

      <el-row>
        <el-table
          :data="returnDataForm.tableData"
          stripe
          style="width: 100%"
          border
          :key="tablekey"
        >
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column label="商品名称" prop="productName">
          </el-table-column>
          <el-table-column label="型号">
            <template slot-scope="scope">
              {{ scope.row.modelName || scope.row.productModel || '无' }}
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              {{ scope.row.productSpecs || '无' }}
            </template>
          </el-table-column>
          <el-table-column label="商品总数" prop="productAmount">
          </el-table-column>
          <el-table-column label="已返还数量" prop="returnAmout">
          </el-table-column>
          <el-table-column label="返还数量" :min-width="120">
            <template slot-scope="scope">
              <el-input-number
                :min="0"
                :max="scope.row.productAmount - scope.row.returnAmout"
                size="small"
                v-model="scope.row.count"
                @change="() => (tablekey = !tablekey)"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <div slot="footer">
        <el-button size="mini" @click="returnDataDialogVisible = false"
          >取消</el-button
        >
        <el-button size="mini" type="primary" @click="appendReturnDataSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改替换货备注对话框 -->
    <el-dialog :visible.sync="editRemarkDialogVisible" title="修改替换货备注">
      <el-form :model="editRemarkForm" label-width="100px">
        <el-form-item label="ID">
          {{ editRemarkForm.id }}
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="editRemarkForm.remark"
            clearable
            placeholder="请输入备注"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="editRemarkDialogVisible = false"
          >取消</el-button
        >
        <el-button size="mini" type="primary" @click="editRemarkSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import snowyiPng from '@/assets/snowyi.png'
import VueBarcode from 'vue-barcode'
import { timeOffset } from '@/utils/zoneTime.js'
import {
  getTypeOptions,
  getStatusOptions,
  getList,
  getById,
  deleteById,
  cancelReplace,
  outReplaceGoods,
  edit,
  addReturnNumber,
  addReturnNumber2,
  editTrackingNumber,
  editTrackingNumber3,
  deleteTrackingNumberById,
  deleteTrackingNumberById2,
  deleteReturnTrackNumber,
  editRemark,
} from '@/api/replace'
export default {
  data() {
    return {
      loading: false,
      form: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        status: null,
        type: null,
        keyWorlds: '',
        isReturn: null,
      },
      typeOptions: [],
      statusOptions: [],
      list: [],
      //订单详情对话框是否隐藏
      showInfoDialogVisible: false,
      //订单信息
      infoData: {},
      // 出库页面对话框是否隐藏
      goOutDialogVisible: false,
      // 出库单表单
      deliveryGoodsProductForm: {
        goOutTime: '',
        customerNumber: '',
        productList: [],
      },
      tablekey: false,
      // 发货订单商品的数据
      deliveryGoodsProductData: [],
      outStorageData: [],
      showOutDetialDialogVisible: false,
      ReplaceOutproductList: [],
      printDataDialog: false,
      showIsReturnDialogVisible: false,
      // outStorageInfo: {
      // //   number: null,
      // //   outStorageType: null,
      // //   outStorageState: null,
      // //   associatedOrderNumber: null,
      // //   orderAmount: null
      // },
      snowyiPng: snowyiPng + '?' + new Date(),
      //替换货创建时间
      replaceCreateTime: '',
      //客户寄回快递信息
      returnInfos: [],
      //修改快递单号弹出框
      changeDialogVisible: false,
      newFormTrackingNumber: {
        id: null,
        TrackingNumber: '',
        newTrackingNumber: '',
      },
      newReturnNumber: '',
      newReturnRemark: '',
      // 寄回商品详情展示
      addReplaceReturnProductForm: {
        replaceStorageId: null,
        ReturnTrackingNumber: '',
        remark: '',
        productList: [],
      },
      // 新版本寄回单
      newReturnDialogVisible: false,
      newReturnForm: {
        replaceStorageId: null,
        returnList: [],
      },
      returnDataDialogVisible: false,
      returnDataForm: {
        isReturn: false,
        trackingNumber: '',
        remark: '',
        tableData: [],
      },
      // 修改订单备注内容
      editRemarkDialogVisible: false,
      editRemarkForm: {
        id: null,
        remark: '',
      },
    }
  },
  components: {
    barcode: VueBarcode,
  },
  created() {
    this.getTypes()

    this.getStatus()

    this.getReplaceList()
  },
  methods: {
    openEditOrderRemarkDialog(row) {
      this.editRemarkForm.id = row.id
      this.editRemarkForm.remark = row.remark
      this.editRemarkDialogVisible = true
    },
    async editRemarkSubmit() {
      await editRemark(this.editRemarkForm)

      this.getReplaceList()

      this.editRemarkDialogVisible = false
    },
    deleteReturnTrackNumberHandler(data) {
      console.log('data data', data)

      this.$confirm('是否删除当前选中的返还信息', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteReturnTrackNumber(data.id)

          this.openNewRetrunReplaceDialog(this.newReturnForm.id)

          this.getReplaceList()
        })
        .catch(() => {})
    },
    async openNewRetrunReplaceDialog(id) {
      let { data } = await getById(id)

      this.newReturnForm.id = data.id

      this.newReturnForm.returnList = data.returnInfos
      this.newReturnForm.isReturn = data.isReturn

      this.newReturnDialogVisible = true
    },
    async appendReturnDataHandle() {
      let { data } = await getById(this.newReturnForm.id)
      this.returnDataForm.tableData = data.details.map((item) => {
        item.count = 0
        return item
      })
      this.returnDataForm.trackingNumber = ''
      this.returnDataForm.reamrk = ''
      this.returnDataDialogVisible = true
    },

    async detailAppendReturnDataHandle() {
      let { data } = await getById(this.infoData.id)
      this.newReturnForm.id = data.id
      this.newReturnForm.returnList = data.returnInfos
      this.newReturnForm.isReturn = data.isReturn

      this.returnDataForm.tableData = data.details.map((item) => {
        item.count = 0
        return item
      })
      this.returnDataForm.trackingNumber = ''
      this.returnDataForm.reamrk = ''
      this.returnDataDialogVisible = true
    },

    async appendReturnDataSubmit() {
      if (!this.returnDataForm.trackingNumber) {
        return this.$message.error('返还快递单号不能为空')
      }

      let filterData = this.returnDataForm.tableData.filter((u) => u.count > 0)

      if (!filterData.some((u) => true)) {
        return this.$message.error('返还商品数量不能为空')
      }

      console.log('filterData', filterData)

      let data = {
        replaceStorageId: this.newReturnForm.id,
        ReturnTrackingNumber: this.returnDataForm.trackingNumber,
        remark: this.returnDataForm.reamrk,
        ProductList: filterData.map((item) => {
          return {
            productId: item.produceId,
            productName: item.productName,
            modelName: item.modelName,
            productSpecs: item.productSpecs,
            count: item.count,
          }
        }),
      }

      await addReturnNumber2(data)

      await this.openNewRetrunReplaceDialog(data.replaceStorageId)

      this.getReplaceList();


      {
        let id = this.newReturnForm.id
        let { data } = await getById(id)
        this.infoData = data
        let details = this.infoData.sendDetails ?? []

        let sendDetailInfos = this.infoData.sendDetailInfos ?? []

        this.replaceCreateTime = this.infoData.createTime
        this.returnInfos = this.infoData.returnInfos

        this.outStorageData = []

        if (sendDetailInfos.some((u) => true)) {
          this.outStorageData = sendDetailInfos.map((item) => {
            return {
              id: item.id,
              TrackingNumber: item.trackingNumber,
              outproductList: item.detials,
              createTime: item.updateTime,
            }
          })
        } else {
          details.map((item) => {
            let existingData = this.outStorageData.find(
              (data) => data.TrackingNumber === item.trackingNumber
            )
            if (existingData) {
              // 如果存在，则直接将商品添加到 outproductList 中
              existingData.outproductList.push(item)
            } else {
              // 否则创建一个新的对象并添加到 outStorageData 中
              let newData = {
                // id: item.id,
                TrackingNumber: item.trackingNumber,
                outproductList: [item],
                createTime: item.updateTime,
              }
              this.outStorageData.push(newData)
            }
          })
        }
      }

      this.returnDataDialogVisible = false
    },

    openNewTrackingDialog(data) {
      if (!data.id) {
        return this.$message.error('老订单不在支持更改')
      }

      this.newFormTrackingNumber = {
        id: data.id,
        newTrackingNumber: '',
        TrackingNumber: data.TrackingNumber,
      }

      this.changeDialogVisible = true
    },
    async getTypes() {
      let { data } = await getTypeOptions()
      this.typeOptions = data
    },
    async getStatus() {
      let { data } = await getStatusOptions()
      this.statusOptions = data
    },
    async getReplaceList() {
      this.loading = true
      let { data } = await getList(this.form)
      this.list = data.data
      console.log(this.list)
      this.form.total = data.total
      this.form.pageIndex = data.pageIndex
      this.form.pageSize = data.pageSize
      this.loading = false
    },
    formQueryHandle() {
      this.form.pageIndex = 1
      this.getReplaceList()
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.getReplaceList()
    },
    handleCurrentChange(val) {
      this.form.pageIndex = val
      this.getReplaceList()
    },
    goAddPage() {
      this.$router.push('/AddReplace')
    },
    goEditPage(row) {
      // let data = await getById(row.id)
      // this.infoData = data.data
      this.$router.push({ path: '/EditReplace/' + row.id })
    },
    getTagType(row) {
      if (row.status == 3) {
        return 'info'
      }

      if (row.status == 4) {
        return 'danger'
      }

      if (row.status == 5) {
        return 'warning'
      }

      if (row.status == 6) {
        return 'success'
      }

      return 'info'
    },
    getReturnTagType(row) {
      if (row.returnStatus == 0) {
        return 'danger'
      }

      if (row.returnStatus == 1) {
        return 'warning'
      }

      if (row.returnStatus == 2) {
        return 'success'
      }

      return 'info'
    },
    deleteData(row) {
      this.$confirm('是否删除当前选中的替换货单', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteById(row.id)

          this.getReplaceList()
        })
        .catch(() => {})
    },
    CancelData(row) {
      this.$confirm('是否取消当前选中的替换货单', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await cancelReplace(row.id)

          this.getReplaceList()
        })
        .catch(() => {})
    },
    async openDetailDialog(row) {
      // console.log(row.companyName)
      let id = row.id
      let { data } = await getById(id)
      this.infoData = data
      let details = this.infoData.sendDetails ?? []

      let sendDetailInfos = this.infoData.sendDetailInfos ?? []

      this.infoData.companyName = row.companyName

      this.replaceCreateTime = this.infoData.createTime
      this.returnInfos = this.infoData.returnInfos

      this.outStorageData = []

      if (sendDetailInfos.some((u) => true)) {
        this.outStorageData = sendDetailInfos.map((item) => {
          return {
            id: item.id,
            TrackingNumber: item.trackingNumber,
            outproductList: item.detials,
            createTime: item.updateTime,
          }
        })
      } else {
        details.map((item) => {
          let existingData = this.outStorageData.find(
            (data) => data.TrackingNumber === item.trackingNumber
          )
          if (existingData) {
            // 如果存在，则直接将商品添加到 outproductList 中
            existingData.outproductList.push(item)
          } else {
            // 否则创建一个新的对象并添加到 outStorageData 中
            let newData = {
              // id: item.id,
              TrackingNumber: item.trackingNumber,
              outproductList: [item],
              createTime: item.updateTime,
            }
            this.outStorageData.push(newData)
          }
        })
      }

      this.showInfoDialogVisible = true
    },
    deleteTrackingNumber(id) {
      // console.log(this.infoData.id)
      // let id = this.infoData.id
      this.$confirm('此操作将删除当前替换货发货单号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          // await deleteTrackingNumberById(id)
          await deleteTrackingNumberById2(id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.showInfoDialogVisible = false
          this.getReplaceList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    openOutDetailDialog() {
      // this.outStorageInfo = this.infoData;
      this.deliveryGoodsProductForm.customerNumber = ''
      this.deliveryGoodsProductForm.goOutTime = ''
      this.deliveryGoodsProductData = this.infoData.details
      this.deliveryGoodsProductData.map((element) => {
        element.count = element.waitSendAmount
      })
      this.goOutDialogVisible = true
    },

    async deliveryGoodsDialogCheck() {
      // console.log(this.deliveryGoodsProductForm)
      if (this.deliveryGoodsProductForm.customerNumber == '') {
        return this.$message.error('当前快递信息不能为空')
      }

      let prodd = [...this.deliveryGoodsProductData]
      // console.log(prodd)

      if (prodd.some((item) => item.count != undefined && item.count != 0)) {
        prodd = prodd.filter((item) => {
          console.log(item.count)
          return item.count && item.count != 0 && item.count != undefined
        })
      } else {
        return this.$message.error('商品数量不能为0')
      }

      prodd = prodd.map((item) => {
        return {
          ReplaceStorageId: item.replaceStorageId,
          ProduceId: item.produceId,
          ProductName: item.productName,
          ProductAttributeId: item.productAttributeId,
          ModelName: item.modelName,
          ProductSpecs: item.productSpecs,
          ProductAmount: item.count,
        }
      })
      // let time = timeOffset(this.deliveryGoodsProductForm.goOutTime)
      // console.log(this.infoData.id)
      // console.log(prodd)
      await outReplaceGoods(
        this.infoData.id,
        prodd,
        this.deliveryGoodsProductForm.customerNumber,
        null,
        this.infoData.number,
        this.infoData.companyName
      )

      this.getReplaceList()

      this.goOutDialogVisible = false

      this.showInfoDialogVisible = false
      this.infoData = {}
    },
    openReplaceGoodsDetialDialog(outproductList) {
      this.ReplaceOutproductList = outproductList
      // console.log(this.ReplaceOutproductList)
      this.showOutDetialDialogVisible = true
    },
    async changeTrackingNumber() {
      let data = {}
      data.replaceStorageId = this.newFormTrackingNumber.id
      data.trackingNumber = this.newFormTrackingNumber.newTrackingNumber
      console.log('changeTrackingNumber data', data)
      // await editTrackingNumber(data)
      await editTrackingNumber3(data)
      this.changeDialogVisible = false
      this.showInfoDialogVisible = false
    },
    async openIsReturn(row) {
      // console.log(row)
      this.newReturnNumber = ''
      this.newReturnRemark = ''
      let id = row.id
      let { data } = await getById(id)
      this.infoData = data
      console.log(data.returnInfos)
      this.showIsReturnDialogVisible = true
    },
    async isReturnChange() {
      console.log(this.newReturnNumber)
      if (this.newReturnNumber != '') {
        if (this.infoData.isReturn != true) {
          this.infoData.returnStatus = 1
        } else {
          this.infoData.returnStatus = 2
        }
      } else {
        this.infoData.returnStatus = 0
      }
      console.log(this.infoData)
      let data = {
        id: this.infoData.id,
        type: this.infoData.type,
        associateOrderNumber: this.infoData.associatedOrderNumber,
        owerUser: this.infoData.owerUser,
        owinUserName: this.infoData.owinUserName,
        isReturn: this.infoData.isReturn,
        returnTrackingNumber: this.infoData.returnTrackingNumber,
        addressee: this.infoData.addressee,
        phone: this.infoData.phone,
        address: this.infoData.address,
        remark: this.infoData.remark,
        returnStatus: this.infoData.returnStatus,
        products: this.infoData.details,
        payType: this.infoData.payType,
      }
      let info = {
        replaceStorageId: this.infoData.id,
        returnTrackingNumber: this.newReturnNumber,
        remark: this.newReturnRemark,
      }
      await edit(data)
      await addReturnNumber(info)
      this.getReplaceList()
      this.showIsReturnDialogVisible = false
    },
    pagination(pageNo, pageSize, array) {
      var offset = (pageNo - 1) * pageSize
      return offset + pageSize >= array.length
        ? array.slice(offset, array.length)
        : array.slice(offset, offset + pageSize)
    },
    //复制到粘贴板
    copyInfo(data) {
      let text =
        '姓名:' +
        data.addressee +
        ' 电话:' +
        data.phone +
        ' 地址:' +
        data.address
      this.copyToClipboard(text)
    },
    copyToClipboard(text) {
      console.log(text)
      if (!navigator.clipboard) {
        // this.fallbackCopyTextToClipboard(text);
        const textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          document.execCommand('copy')
          this.$message({
            message: '已复制到剪贴板',
            type: 'success',
          })
        } catch (err) {
          console.error('复制失败:', err)
        }
        document.body.removeChild(textArea)
        return
      }
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$message({
            message: '已复制到剪贴板',
            type: 'success',
          })
        })
        .catch((err) => {
          console.error('复制失败:', err)
        })
    },
    // 打印出货单
    printpage() {
      let newStr = this.$refs.printBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
    zjPrintPage() {
      let newStr = this.$refs.zhijieprintBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
  },
  computed: {
    deliveryGoodsDetialCount() {
      return this.ReplaceOutproductList.reduce((pre, cur) => {
        pre += cur.productAmount
        return pre
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.print_box {
  width: 700px;
  border: 1px solid transparent;
  margin: 100px auto 0;
  font-size: 16px;
  .print_box_head {
    position: relative;
    height: 50px;
    text-align: center;
    line-height: 50px;
    .barcode_style {
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
    }
  }
  .print_box_info {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 230px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
  .print_table_box {
    font-size: 12px;
    table {
      border-top: 1px solid black;
      border-left: 1px solid black;
      th,
      td {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
    thead {
      line-height: 28px;
      th {
        font-weight: normal;
      }
    }
    tbody {
      line-height: 16px;
      td {
        padding-left: 5px;
        box-sizing: border-box;
      }
    }
  }
  .print_box_foot {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 266px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
}

.return_item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
</style>
